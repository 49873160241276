import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { ExerciseTextArea, Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Find Your Idea self-paced workshop";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "find-your-idea-worksheet"
    }}>{`Find Your Idea worksheet`}</h1>
    <p>{`Use this worksheet to work on your own idea. Print if you want a PDF or a piece of paper, use the provided textareas to save to local storage.`}</p>
    <p>{`Remember, all inputs are local. Nobody else can see this :)`}</p>
    <p>{`Follow these steps and you'll be well on your way to a successful project.`}</p>
    <p>{`Good luck! ❤️`}</p>
    <ExerciseTextArea name="worksheet:idea" caption="Name your idea" short mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:why" caption="Art, portfolio, my itch, others' itch?" short mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:problem" caption="Does your idea solve a problem? Which one? Be as detailed as possible" mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:adjacent_possible" caption="Did something new become possible to make this problem more solvable?" mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-1" caption="Has the idea stuck with you for more than 2 weeks?" short mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-2" caption="Find 5 people to talk to. What do they say?" long mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-2-1" caption="Has talking to people changed your idea, how?" mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-3" caption="Explain your solution to 5 people. Do they want it? What did they say?" mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-4" caption="Can you solve the problem with a prototype?" mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-4-1" caption="Did the prototype work?" short mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:validation-5" caption="Put up a charge page with a low-end price. Do people buy?" short mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:audience" caption="Who is your idea for? Where do you find them? What do they look like?" long mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:why-you" caption="Is this the right time? Are you the right person? Think hard" mdxType="ExerciseTextArea" />
    <ExerciseTextArea name="worksheet:investor" caption="Think like an investor. Is this the highest expected value idea you can work on right now?" mdxType="ExerciseTextArea" />
    <p>{`If you came this far and you still think this is the best thing ever, `}<strong parentName="p"><em parentName="strong">{`go for it`}</em></strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      